import { createTheme } from '@mui/material/styles';

import typography from './typography';
import palette from './palette';

// it could be your App.tsx file or theme file that is included in your tsconfig.json
import { Theme } from '@mui/material/styles';
import MuiTypography from './overrides/MuiTypography';
import MuiInputBase from './overrides/MuiInput';
import MuiInputLabel from './overrides/MuiInputLabel';

declare module '@mui/styles' {
  // eslint-disable-next-line no-unused-vars
  interface DefaultTheme extends Theme {}
}

// We will be adding MUI theme related changes in here.
const theme = createTheme({
  palette,
  typography,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          textDecoration: 'none',
          borderRadius: 20,
          color: 'black',
          fontSize: '1rem',
          textTransform: 'none',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
        },
      },
    },
    MuiTypography: { styleOverrides: MuiTypography },
    MuiInputBase: { styleOverrides: MuiInputBase },
    MuiInputLabel: { styleOverrides: MuiInputLabel },
  },
});

export default theme;
